import { FilterOptionSchema } from '../redux/oms/omsTypes';

// Function to check if a value is null
export function isNull(value: unknown): boolean {
  return value == null;
}

// Function to check if a value is not null
export function isNotNull(value: unknown): boolean {
  return value != null;
}

// Function to check if a value is undefined
export function isUndefined(value: unknown): boolean {
  return typeof value === 'undefined';
}

// Function to check if a value is not undefined
export function isNotUndefined(value: unknown): boolean {
  return typeof value !== 'undefined';
}

// Function to check if a value is null or undefined
export function isNullOrUndefined(value: unknown): boolean {
  return value == null || typeof value === 'undefined';
}

// Function to check if a value is not null or undefined
export function isNotNullOrUndefined(value: unknown): boolean {
  return value != null && typeof value !== 'undefined';
}

export function round(value: number, digit: number): number {
  return Math.round(value * Math.pow(10, digit)) / Math.pow(10, digit);
}

export function FinalPrice(base_price: number, margin: number) {
  return (base_price * 100) / (100 - margin);
}

export function formatValue(value: number) {
  if (value === 0) {
    return '0';
  }
  if (value > 10000000) {
    return round(value / 10000000, 2) + ' Cr';
  }
  if (value > 100000) {
    return round(value / 100000, 2) + ' L';
  }
  return round(value / 1000, 2) + ' K';
}

export function capitalize(val: string) {
  return val.replace(/\b\w/g, (l) => l.toUpperCase());
}

export function commaFormat(value: string) {
  let nonDecimal = value;
  let decimal = '';
  if (value.indexOf('.') > -1) {
    nonDecimal = value.split('.')[0];
    decimal = value.split('.')[1];
  }
  let result = '';
  const commas = [3, 5, 7, 9, 11];
  let curIndex = 0;
  nonDecimal
    .toString()
    .split('')
    .reverse()
    .forEach((val, index) => {
      if (index === commas[curIndex]) {
        result = result + ',' + val;
        curIndex = curIndex + 1;
      } else {
        result = result + val;
      }
    });
  return (
    result.split('').reverse().join('') + (decimal === '' ? '' : `.${decimal}`)
  );
}

export const formatDate = (actualDelivery: string) => {
  if (actualDelivery == null) {
    return '';
  }
  const date = new Date(actualDelivery);
  const options = {
    day: '2-digit' as const,
    month: '2-digit' as const,
    year: 'numeric' as const,
  };
  const formattedDate = date.toLocaleDateString('en-IN', options);
  return formattedDate.replace(/\//g, '-');
};

export const formatTime = (actualDelivery: string) => {
  if (actualDelivery == null) {
    return '';
  }

  const date = new Date(actualDelivery);

  const timeOptions = {
    hour: 'numeric' as const,
    minute: '2-digit' as const,
    hour12: true as const,
  };

  const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

  return `${formattedTime}`;
};

export const has_role = (user: FilterOptionSchema, role: string) => {
  if (user?.role && user.role.indexOf(role) > -1) {
    return true;
  }
  return false;
};

export const iconList = {
  pdf: 'https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012114327-document.svg',
  xlsx: 'https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012114327-document.svg',
  commonFile:
    'https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240119130001-file-text.svg',
};

export const getFileIcon = (file: { name: string; url?: string }) => {
  if (file.url) {
    if (file.url.endsWith('.pdf')) {
      return iconList.pdf;
    }
    if (file.url.endsWith('.xlsx') || file.url.endsWith('.csv')) {
      return iconList.xlsx;
    }
    if (
      file.url.endsWith('.svg') ||
      file.url.endsWith('.webp') ||
      file.url.endsWith('.png') ||
      file.url.endsWith('.jpeg') ||
      file.url.endsWith('.jpg')
    ) {
      return file.url;
    }
    return iconList.commonFile;
  }
  return iconList.pdf;
};
