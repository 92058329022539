import React, { useEffect, useState } from 'react';
import './SourcingProductCard.scss';
import { ProductDetailSchema, TaskProductDetails } from '../../models/Task';
import OutsideAlerter from '../../utils/OutsideClickDetector';
import { useDispatch } from 'react-redux';
import {
  addVendorDetail,
  deleteTaskProduct,
  deleteVendorDetails,
  newCompanyStart,
  updateVendorDetails,
} from '../../redux/oms/omsActions';
import ApiService from '../../services/apiService';
import URL from '../../urls/Urls';
import { FinalPrice, round } from '../../utils/Helper';
import Filter from '../oms/Options/Filter/Filter';
import { useSelector } from 'react-redux';
import { selectFilterOptions } from '../../redux/oms/omsSelectors';
import { filters } from '../oms/Options/Filter/Filter.data';
import {
  FilterOptionSchema,
  NewClientPayloadSchema,
} from '../../redux/oms/omsTypes';
import NewCompanyForm from '../oms/NewCompanyForm/NewCompanyForm';
import { NegotiationHistoryModal } from './NegotiationHistoryModal/NegotiationHistoryModal';
import { VendorNegotiationSchema } from '../../models/VendorNegotiation';
import { toast } from 'react-toastify';
import { toastOptions } from '../../utils/toast';
import XetToggle from '../shared/xet-toggle/XetToggle';
import XetModal from '../shared/xet-modal/XetModal';
import QuotationDataModal from './QuotationDataModal/QuotationDataModal';

interface ProductCardProps {
  products: ProductDetailSchema[];
  address: TaskProductDetails;
  category: string;
  category_id: number;
  assigneeName: string;
}

const SourcingProductCard: React.FC<ProductCardProps> = ({
  products,
  address,
  category,
  category_id,
  assigneeName,
}) => {
  const dispatch = useDispatch();
  const [productList, setProductList] = useState<ProductDetailSchema[]>([]);
  const [openDropdown, setOpenDropdown] = useState('');
  const [showHistoryIndex, setShowHistoryIndex] = useState('');
  const [showVendorIndex, setShowVendorIndex] = useState(-1);
  const [openNewVendorForm, setOpenNewVendorForm] = useState(false);
  const [vendorData, setNewVendorData] = useState<{
    name: string;
    tpm_id: number;
  } | null>();
  const [vendorNegotiationData, setVendorNegotiationData] = useState<
    VendorNegotiationSchema[] | null
  >(null);
  const [productAcceptedStates, setProductAcceptedStates] = useState<{
    [key: number]: boolean | undefined;
  }>({});
  const [disableSetMap, setDisableSetMap] = useState(false);
  const [openQuotationDataModal, setOpenQuotationDataModal] = useState(false);

  useEffect(() => {
    setProductList(products);
    if (!disableSetMap) {
      setProductAcceptedStates(
        products.reduce(
          (acc: { [key: number]: boolean | undefined }, product) => {
            acc[product.tpm_id] = product.product_accepted;
            return acc;
          },
          {}
        )
      );
    }
  }, [products]);

  const handleDropdown = (pindex: number, index: number) => {
    if (openDropdown === '') {
      setOpenDropdown(`${pindex}-${index}`);
    }
    if (openDropdown === `${pindex}-${index}`) {
      setOpenDropdown('');
    }
  };

  const closeStatus = (pindex: number, index: number) => {
    if (openDropdown === `${pindex}-${index}`) {
      handleDropdown(pindex, index);
    }
  };

  const handleApprove = (vendor_id: number, tpm_id?: number) => {
    setOpenDropdown('');
    dispatch(
      updateVendorDetails({
        tpm_id: tpm_id,
        vendor_id: vendor_id,
        approved: true,
      })
    );
    toast.success('Vendor Approved!', toastOptions);
  };

  const handleReject = (vendor_id: number, tpm_id?: number) => {
    setOpenDropdown('');
    dispatch(
      updateVendorDetails({
        tpm_id: tpm_id,
        vendor_id: vendor_id,
        approved: false,
      })
    );
    toast.success('Vendor Rejected!', toastOptions);
  };

  const handleDeleteVendorMapping = (vendor_id: number, tpm_id: number) => {
    dispatch(
      deleteVendorDetails({
        sourcingId: category_id,
        tpm_id: tpm_id,
        vendor_id: vendor_id,
      })
    );
    toast.success('Vendor Deleted!', toastOptions);
  };

  const handleShowHistory = async (tpm_id: number, vendor_id: number) => {
    const vendorIndex = `${tpm_id}-${vendor_id}`;
    if (vendorIndex === showHistoryIndex) {
      setShowHistoryIndex('');
    } else {
      const { data } = await ApiService().client.get(
        URL.OMS.VENDOR_NEGOTIATION(category_id),
        {
          params: {
            tpm_id: tpm_id,
            vendor_id: vendor_id,
          },
        }
      );
      setVendorNegotiationData(data.response);
      setShowHistoryIndex(vendorIndex);
    }
  };

  const toggleShowHistory = (tpm_id: number, vendor_id: number) => {
    const vendorIndex = `${tpm_id}-${vendor_id}`;
    if (showHistoryIndex === vendorIndex) {
      setShowHistoryIndex('');
    }
  };

  const onEnterPress = (
    e: React.KeyboardEvent,
    key: string,
    tpm_id: number,
    vendor_id?: number
  ) => {
    const value = (e.target as HTMLInputElement).value;
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (vendor_id) {
        dispatch(
          updateVendorDetails({
            tpm_id: tpm_id,
            vendor_id: vendor_id,
            [key]: value,
          })
        );
      } else {
        dispatch(
          updateVendorDetails({
            tpm_id: tpm_id,
            [key]: value,
          })
        );
      }
      toast.success(`${key} updated!`, toastOptions);
    }
  };

  const productChangeHandler = (
    e: React.ChangeEvent,
    pindex: number,
    key: 'margin' | 'vendor_target_price'
  ) => {
    const value = (e.target as HTMLInputElement).value as any;
    const plist = [...productList];
    plist[pindex][key] = value;
    setProductList(plist);
  };

  const vendorChangeHandler = (
    e: React.ChangeEvent,
    pindex: number,
    index: number,
    key: 'price'
  ) => {
    const value = (e.target as HTMLInputElement).value as any;
    const plist = [...productList];
    const vendorList = plist[pindex].vendor_details;
    if (vendorList != undefined && vendorList?.length > 0) {
      vendorList[index][key] = value;
      setProductList(plist);
    }
  };

  const vendors = useSelector((state) =>
    selectFilterOptions(state, filters[2].key)
  );

  const addNewVendorHandler = (index: number) => {
    setShowVendorIndex(index);
  };

  const addNewVendor = (payload: NewClientPayloadSchema, tpm_id: number) => {
    dispatch(newCompanyStart(payload));
    setOpenNewVendorForm(false);
    setNewVendorData({ name: payload.company_name, tpm_id });
    setShowVendorIndex(-1);
  };

  const loadVendors = (val: FilterOptionSchema[], tpm_id: number) => {
    dispatch(
      addVendorDetail({
        sourcingId: category_id,
        tpm_id,
        vendor_id: val[0].id,
      })
    );
    setShowVendorIndex(-1);
  };

  const handleProductDelete = (tpm_id: number) => {
    dispatch(deleteTaskProduct({ tpm_id: tpm_id }));
    toast.success('Product Deleted!', toastOptions);
  };

  const handleProductAcceptedToggle = (tpm_id: number, accepted: boolean) => {
    setDisableSetMap(true);
    setProductAcceptedStates((prevState) => ({
      ...prevState,
      [tpm_id]: accepted,
    }));
    dispatch(
      updateVendorDetails({
        tpm_id: tpm_id,
        product_accepted: accepted,
      })
    );
  };

  useEffect(() => {
    if (vendorData) {
      const entry = vendors.filter((row) => row.name === vendorData?.name)[0];
      dispatch(
        addVendorDetail({
          sourcingId: category_id,
          tpm_id: vendorData.tpm_id,
          vendor_id: entry.id,
        })
      );
      setNewVendorData(null);
    }
  }, [vendors]);

  return (
    <>
      <div className="card-header">
        <div className="address xetgo-font-tag">
          {address.address_line}, {address.address_city},{' '}
          {address.address_state}, {address.address_country},{' '}
          {address.address_pincode}
        </div>
        {category === 'quotation' && (
          <button
            onClick={() => setOpenQuotationDataModal(true)}
            className="report-download-button flex-row align-items-center gap-8"
          >
            Quotation Report
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624120337-download.svg"
              alt="download"
              height={12}
              width={12}
            />
          </button>
        )}
      </div>
      {productList.map((product, pindex) => {
        return (
          <div key={product.tpm_id} className="flex-column gap-12">
            <div className="sourcing-product-card-container flex-row justify-content-space-between gap-12">
              <div className="sourcing-mid-title-container flex-row align-items-center gap-4">
                <p className="product-name xetgo-font-tag bolder">
                  {product.name},
                </p>
                <span className="material-name xetgo-font-tag">
                  {product.material}
                </span>
              </div>
              <div className="sourcing-mid-right-input-containers flex-row gap-8">
                <div className="disabled-box-container flex-row gap-8">
                  <p className="xetgo-font-tag">Quantity</p>
                  <p className="xetgo-font-bolder">
                    {product.quantity} <span>{product.unit || 'U'}</span>{' '}
                  </p>
                </div>
                {category === 'sourcing' && (
                  <div className="disabled-box-container flex-row gap-8">
                    <p className="xetgo-font-tag">Target Price</p>
                    <p className="xetgo-font-bolder">
                      {product.vendor_target_price || ''}
                    </p>
                    <span className="xetgo-font-bolder">₹</span>
                  </div>
                )}
                {category === 'quotation' && (
                  <div className="disabled-box-container flex-row gap-8">
                    <p className="xetgo-font-tag">Vendor Price</p>
                    <p className="xetgo-font-bolder">
                      {product.vendor_details
                        ? product.vendor_details[0]?.price
                        : '-'}
                    </p>
                    <span className="xetgo-font-bolder">₹</span>
                  </div>
                )}
                {category === 'quotation' && (
                  <div className="disabled-box-container flex-row gap-8">
                    <p className="xetgo-font-tag">Final Price</p>
                    <p className="xetgo-font-bolder">
                      {`${
                        product.vendor_details &&
                        product.vendor_details[0]?.price
                          ? round(
                              FinalPrice(
                                product.vendor_details[0]?.price,
                                product.margin || 0
                              ),
                              2
                            )
                          : '-'
                      }`}
                    </p>
                    <span className="xetgo-font-bolder">₹</span>
                  </div>
                )}
                {category === 'quotation' && (
                  <div className="disabled-box-container flex-row gap-8">
                    <p className="xetgo-font-tag">Client Target Price</p>
                    <p className="xetgo-font-bolder">
                      {product.client_target_price}
                    </p>
                    <span className="xetgo-font-bolder">₹</span>
                  </div>
                )}
                {/* <span className="flex-row align-items-center">
                  <img
                    className="sourcing-img-icons cursor-pointer"
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114095750-trash.svg"
                    alt="trash"
                    onClick={() => handleProductDelete(product.tpm_id)}
                  />
                </span> */}

                <XetToggle
                  onToggle={(value: boolean) =>
                    handleProductAcceptedToggle(product.tpm_id, value)
                  }
                  toggleValue={productAcceptedStates[product.tpm_id]}
                  backgroundColor="#FFFFFF"
                  disableColor="#FF5252"
                  enableColor="#00C414"
                />
              </div>
            </div>
            <div className="vendors-container p-8">
              <textarea
                className="productCardTextArea xetgo-font-tag product-input py-6 px-8 flex-1"
                defaultValue={product.description}
                onKeyDown={(e) => {
                  onEnterPress(e, 'description', product.tpm_id);
                }}
              />
              {product.vendor_details &&
                product.vendor_details.map((vendor, index) => {
                  return (
                    <div key={index}>
                      {category === 'sourcing' && (
                        <div
                          className="vendor-container sourcing-vendors gap-8"
                          key={index}
                        >
                          <input
                            disabled
                            className="sourcing-vendors-input xetgo-font-tag"
                            value={vendor.name}
                          />
                          <div style={{ position: 'relative' }}>
                            <input
                              className="sourcing-vendors-input quantity xetgo-font-tag"
                              value={vendor.price || ''}
                              onKeyDown={(e) =>
                                onEnterPress(
                                  e,
                                  'price',
                                  product.tpm_id,
                                  vendor.vendor_id
                                )
                              }
                              onChange={(e) =>
                                vendorChangeHandler(e, pindex, index, 'price')
                              }
                            />
                            <span className="rupee-unit xetgo-font-tag">₹</span>
                          </div>
                          <OutsideAlerter
                            action={() => closeStatus(pindex, index)}
                            dependency={[openDropdown]}
                          >
                            <div
                              className="sourcing-dropdown-status flex-row gap-10 align-items-center xetgo-font-tag cursor-pointer"
                              style={{ backgroundColor: 'white' }}
                            >
                              <p
                                className={`status ${
                                  vendor.approved && 'approve'
                                } ${
                                  vendor.approved != null &&
                                  !vendor.approved &&
                                  'reject'
                                }`}
                                onClick={() => {
                                  handleDropdown(pindex, index);
                                }}
                              >
                                {vendor.approved != null && (
                                  <>
                                    {vendor.approved ? 'Approved' : 'Rejected'}
                                    <img
                                      src={
                                        vendor.approved
                                          ? 'https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114080249-checkmark-circle.svg'
                                          : 'https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114080249-close-circle.svg'
                                      }
                                      alt={
                                        vendor.approved
                                          ? 'Approved'
                                          : 'Rejected'
                                      }
                                    />
                                  </>
                                )}
                              </p>
                              <img
                                className="sourcing-img-icons cursor-pointer"
                                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114094003-Vector+%28Stroke%29.svg"
                                alt="dropdown-arrow"
                                onClick={() => {
                                  handleDropdown(pindex, index);
                                }}
                              />

                              {openDropdown === `${pindex}-${index}` && (
                                <div className="dropdown-box flex-column gap-4 align-items-center flex-row">
                                  <p
                                    onClick={() => {
                                      handleApprove(
                                        vendor.vendor_id,
                                        product.tpm_id
                                      );
                                    }}
                                    className="status approve"
                                  >
                                    Approved
                                    <img
                                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114080249-checkmark-circle.svg"
                                      alt="approve"
                                    />
                                  </p>
                                  <p
                                    onClick={() =>
                                      handleReject(
                                        vendor.vendor_id,
                                        product.tpm_id
                                      )
                                    }
                                    className="status reject"
                                  >
                                    Rejected
                                    <img
                                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114080249-close-circle.svg"
                                      alt="reject"
                                    />
                                  </p>
                                </div>
                              )}
                            </div>
                          </OutsideAlerter>
                          <div className="flex-row gap-10 align-items-center position-relative">
                            <img
                              className="sourcing-img-icons cursor-pointer"
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114095750-trash.svg"
                              alt="trash"
                              onClick={() =>
                                handleDeleteVendorMapping(
                                  vendor.vendor_id,
                                  product.tpm_id
                                )
                              }
                            />
                            <OutsideAlerter
                              action={() => {
                                toggleShowHistory(
                                  product.tpm_id,
                                  vendor.vendor_id
                                );
                              }}
                              dependency={[showHistoryIndex]}
                            >
                              <img
                                onClick={() =>
                                  handleShowHistory(
                                    product.tpm_id,
                                    vendor.vendor_id
                                  )
                                }
                                className="sourcing-img-icons flex-row align-items-center cursor-pointer"
                                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114095807-history.svg"
                                alt="history"
                              />
                              {showHistoryIndex ===
                                `${product.tpm_id}-${vendor.vendor_id}` && (
                                <NegotiationHistoryModal
                                  vendorNegotiationData={vendorNegotiationData}
                                />
                              )}
                            </OutsideAlerter>
                          </div>
                        </div>
                      )}
                      {category === 'quotation' && (
                        <div
                          className="vendor-container quotation-vendors p-8 gap-8"
                          key={index}
                        >
                          <input
                            disabled
                            className="sourcing-vendors-input"
                            value={vendor.name}
                          />
                          <div className="position-relative flex-row gap-12 align-items-center">
                            <p className="xetgo-font-tag">Margin</p>
                            <input
                              className="sourcing-vendors-input quantity xetgo-font-tag"
                              value={product.margin}
                              onKeyDown={(e) =>
                                onEnterPress(
                                  e,
                                  'margin',
                                  product.tpm_id,
                                  vendor.vendor_id
                                )
                              }
                              onChange={(e) =>
                                productChangeHandler(e, pindex, 'margin')
                              }
                            />
                            <span className="rupee-unit xetgo-font-tag">%</span>
                          </div>
                          <div className="position-relative flex-row gap-12 align-items-center">
                            <p className="xetgo-font-tag">
                              Vendor Target Price
                            </p>
                            <input
                              className="sourcing-vendors-input quantity xetgo-font-tag"
                              value={product.vendor_target_price}
                              onKeyDown={(e) =>
                                onEnterPress(
                                  e,
                                  'vendor_target_price',
                                  product.tpm_id,
                                  vendor.vendor_id
                                )
                              }
                              onChange={(e) =>
                                productChangeHandler(
                                  e,
                                  pindex,
                                  'vendor_target_price'
                                )
                              }
                            />
                            <span className="rupee-unit xetgo-font-tag">₹</span>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              {showVendorIndex == pindex && (
                <div style={{ marginBottom: '8px' }}>
                  <Filter
                    option={{ key: 'client', name: 'Vendor Name' }}
                    optionList={vendors}
                    multipleSelection={false}
                    onSelection={(val, _option) => {
                      loadVendors(val, product.tpm_id);
                    }}
                    mode="showNames"
                    showPlaceholder={false}
                    borderStyle="solid"
                    addNew={true}
                    onAddNew={() => setOpenNewVendorForm(true)}
                  />
                </div>
              )}

              {openNewVendorForm && (
                <div className="new-user-form-container position-relative">
                  <NewCompanyForm
                    type="VENDOR"
                    onCancel={() => setOpenNewVendorForm(false)}
                    onAddClient={(val) => addNewVendor(val, product.tpm_id)}
                  />
                </div>
              )}
              {category === 'sourcing' && (
                <span
                  className="addProductBtnContainer flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                  style={{
                    color: '#5151EC',
                  }}
                  onClick={() => {
                    addNewVendorHandler(pindex);
                  }}
                >
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                    alt="plus"
                    width={16}
                    height={16}
                  />
                  Add Vendor
                </span>
              )}
            </div>
          </div>
        );
      })}

      <XetModal
        backdropClose={true}
        open={openQuotationDataModal}
        handleClose={() => setOpenQuotationDataModal(false)}
      >
        <QuotationDataModal
          address={address}
          products={products.filter(
            (product, index) => product.product_accepted
          )}
          handleClose={() => setOpenQuotationDataModal(false)}
          assigneeName={assigneeName}
        ></QuotationDataModal>
      </XetModal>
    </>
  );
};

export default SourcingProductCard;
