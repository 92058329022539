import React, { useEffect, useState } from 'react';
import './NewAddressForm.scss';
import {
  FilterOptionSchema,
  NewCompanyAddressPayloadSchema,
} from '../../../redux/oms/omsTypes';
import { toast } from 'react-toastify';
import { toastOptions } from '../../../utils/toast';
import { ALL_COUNTRIES } from './AllCountries.data';
import FilterDropdown from '../Options/FilterDropdown/FilterDropdown';
import Filter from '../Options/Filter/Filter';
import { capitalize, isNullOrUndefined } from '../../../utils/Helper';

interface NewAddressFormProps {
  type: 'CLIENT' | 'VENDOR' | 'POC' | 'CONTACT';
  user_id: number;
  onCancel: () => void;
  onAddAddress: (payload: NewCompanyAddressPayloadSchema) => void;
}

const initForm = (user_id: number) => ({
  line: '',
  city: '',
  state: '',
  pincode: '',
  country: '',
  user_id: user_id,
});

type NewAddressKeys =
  | 'line'
  | 'city'
  | 'state'
  | 'pincode'
  | 'country'
  | 'user_id';

const NewAddressForm: React.FC<NewAddressFormProps> = ({
  type,
  user_id,
  onCancel,
  onAddAddress,
}) => {
  const [formData, setFormData] = useState<NewCompanyAddressPayloadSchema>(
    initForm(user_id)
  );
  const [pincode, setPincode] = useState('');
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [country, setCountry] = useState<FilterOptionSchema>(
    ALL_COUNTRIES.find((val) => val.code === 'IN') || ALL_COUNTRIES[0]
  );

  const getPincodeDetails = async (pin: string) => {
    if (country) {
      try {
        setPincodeLoading(true);
        const options = {
          method: 'GET',
          headers: {
            'X-RapidAPI-Key':
              'd2ba37bb9dmsh16647c2f4fce337p1d15dfjsn671368721856',
            'X-RapidAPI-Host': 'worldwide-zip-code-api.p.rapidapi.com',
          },
        };
        const response = await fetch(
          `https://worldwide-zip-code-api.p.rapidapi.com/zipcode?zip=${pin}`,
          options
        );

        const data = await response.json();

        const pincodeDetails = data[0];
        {
          pincodeDetails
            ? setFormData((formData) => ({
                ...formData,
                state: capitalize(pincodeDetails.state),
                city: capitalize(pincodeDetails.city),
                country: capitalize(pincodeDetails.country),
              }))
            : setFormData((formData) => ({
                ...formData,
                state: '',
                city: '',
                country: '',
              }));
        }
        setPincodeLoading(false);
        if (data.length <= 0) {
          toast.error('Invalid Pincode!', toastOptions);
        }
      } catch (error) {
        toast.error('Invalid Pincode!', toastOptions);
      }
    }
  };

  useEffect(() => {
    setFormData((data) => {
      return {
        ...data,
        role: type.toUpperCase(),
      };
    });
  }, []);

  useEffect(() => {
    if (country?.name) {
      setFormData((data) => {
        return {
          ...data,
          country: country.name,
        };
      });
    }
  }, [country]);

  const onAddAddressHandler = () => {
    console.log('data:', formData);
    if (
      Object.keys(formData).filter(
        (key) => formData[key as NewAddressKeys] === ''
      ).length > 0
    ) {
      toast.error('All fields are compulsory', toastOptions);
    } else {
      onAddAddress(formData);
    }
  };

  const updateFormEntry = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: NewAddressKeys
  ) => {
    setFormData((data) => {
      return {
        ...data,
        [key]: (event.target as HTMLInputElement).value as string,
      };
    });
  };

  return (
    <div className="px-12 py-16 new-client-form-container flex-column gap-24">
      <p className="title xetgo-font-button-bold">Add New Address</p>
      <div className="new-client-form flex-column gap-12">
        <div className="new-client-entry flex-column gap-8">
          <p className="label xetgo-font-tag">Delivery Address</p>
          <input
            className="new-client-form-input xetgo-font-tag"
            placeholder="43/B Apartment Building, Sector 3, HSR Layout"
            value={formData.line}
            onChange={(e) => updateFormEntry(e, 'line')}
          />
        </div>
        <div className="address-form-middle-container flex-row gap-12">
          <div className="new-client-entry flex-column gap-8">
            <p className="label xetgo-font-tag">Country</p>
            <Filter
              option={{
                key: 'country',
                name: 'Country',
              }}
              optionList={ALL_COUNTRIES}
              multipleSelection={false}
              onSelection={(val, _option) => {
                console.log('VAL:', val);
                setCountry(val[0]);
              }}
              mode="showNames"
              showPlaceholder={false}
              borderStyle="solid"
              preSelected={country ? [country] : []}
              direction="left"
              addNew={false}
            />
          </div>
          <div className="new-client-entry flex-column gap-8">
            <p className="label xetgo-font-tag">Pincode</p>
            <input
              className="new-client-form-input xetgo-font-tag"
              placeholder="496120"
              value={formData.pincode}
              onChange={(e) => {
                updateFormEntry(e, 'pincode');
                setPincode(e.target.value);
              }}
            />
            {pincodeLoading ? (
              <img
                onClick={() => getPincodeDetails(pincode)}
                className="pincode_forward cursor-pointer"
                src="https://i.gifer.com/origin/34/34338d26023e5515f6cc8969aa027bca.gif"
                alt="forward"
              />
            ) : (
              <img
                onClick={() => getPincodeDetails(pincode)}
                className="pincode_forward cursor-pointer"
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231130104346-arrow-forward.svg"
                alt="forward"
              />
            )}
          </div>
          <div className="new-client-entry flex-column gap-8">
            <p className="label xetgo-font-tag">State</p>
            <input
              className="new-client-form-input xetgo-font-tag"
              placeholder="State ex: Delhi"
              value={formData.state}
              onChange={(e) => updateFormEntry(e, 'state')}
            />
          </div>
          <div className="new-client-entry flex-column gap-8">
            <p className="label xetgo-font-tag">City</p>
            <input
              className="new-client-form-input xetgo-font-tag"
              placeholder="City ex: Pune"
              value={formData.city}
              onChange={(e) => updateFormEntry(e, 'city')}
            />
          </div>
        </div>
      </div>
      <div className="flex-row gap-12 new-form-btn-container">
        <div className="cancel-btn xetgo-font-tag" onClick={onCancel}>
          Cancel
        </div>
        <div className="add-btn xetgo-font-tag" onClick={onAddAddressHandler}>
          Add Address
        </div>
      </div>
    </div>
  );
};

export default NewAddressForm;
