import React, { useEffect, useState } from 'react';
import './XetDeliveryCalendar.scss';
import SideNav from '../shared/sidenav/SideNav';
import Filter from '../oms/Options/Filter/Filter';
import { useSelector } from 'react-redux';
import { selectFilterOptions } from '../../redux/oms/omsSelectors';
import { filters } from '../oms/Options/Filter/Filter.data';
import { FilterOptionSchema } from '../../redux/oms/omsTypes';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ApiService from '../../services/apiService';
import URL from '../../urls/Urls';
import { DeliverySchedule } from '../../models/DeliverySchedule';
import { formatDate } from '../../utils/Helper';
import { useDispatch } from 'react-redux';
import { fetchFilter } from '../../redux/oms/omsActions';
import { XET_DELIVERY_CARD_STATUSES } from './XetDelivery.data';
import { useNavigate } from 'react-router-dom';
import environment from '../../environments/environment';
import { Tooltip } from '@mui/material';
import XetImage from '../shared/xet-image/XetImage';

const XetDeliveryCalendar: React.FC = () => {
  const dispatch = useDispatch();
  const [today, _setToday] = useState<string>(
    formatDate(dayjs().toDate().toISOString())
  );
  const [startDate, setStartDate] = useState<Date>(dayjs().toDate());
  const [startDateOpen, setStartDateOpen] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date>(dayjs().add(7, 'days').toDate());
  const [endDateOpen, setEndDateOpen] = useState<boolean>(false);
  const [deliveryScheduleData, setDeliveryScheduleData] =
    useState<DeliverySchedule[]>();
  const clients = useSelector((state) =>
    selectFilterOptions(state, filters[1].key)
  );
  const assignees = useSelector((state) =>
    selectFilterOptions(state, filters[0].key)
  );
  const [selectedClients, setSelectedClients] = useState<FilterOptionSchema[]>(
    []
  );
  const [selectedAssignees, setSelectedAssignees] = useState<
    FilterOptionSchema[]
  >([]);
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<
    string | null
  >();
  const [tabSelected, setTabSelected] = useState<'VENDOR' | 'CLIENT'>('CLIENT');

  useEffect(() => {
    dispatch(fetchFilter('XeTrack'));
  }, []);

  useEffect(() => {
    getDeliveryScheduleData().then((r) => {
      return r;
    });
  }, [
    startDate,
    endDate,
    selectedClients,
    selectedDeliveryType,
    selectedAssignees,
    tabSelected,
  ]);

  const filterByClient = (value: FilterOptionSchema[]) => {
    setSelectedClients(value);
  };
  const filterByAssignees = (value: FilterOptionSchema[]) => {
    setSelectedAssignees(value);
  };

  const getDeliveryScheduleData = async () => {
    const client_ids = selectedClients.map((val) => val.id);
    let assignees_ids;
    if (selectedAssignees.length > 0) {
      assignees_ids = selectedAssignees.map((val) => val.id);
    }
    const response = await ApiService().client.get(
      URL.OMS.DELIVERY_SCHEDULE_DATA,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
          client_ids,
          delivery_type: selectedDeliveryType,
          assignee_ids: assignees_ids,
          tab: tabSelected,
        },
      }
    );
    setDeliveryScheduleData(response.data.delivery_schedules);
  };

  const markDelivered = async (id: number) => {
    const response = await ApiService().client.put(
      URL.OMS.DELIVERY_COMPLETED(id)
    );
    const newSchedule = deliveryScheduleData?.map((schedule) => {
      const data = schedule.data.map((delivery) => {
        if (delivery.id === id) {
          delivery.actual_delivery_date = response.data.delivery_date;
        }
        return delivery;
      });
      return { ...schedule, data };
    });
    setDeliveryScheduleData(newSchedule);
  };

  const startDateSelected = (event: any) => {
    setStartDate(event.toDate());
    toggleStartDate();
  };

  const endDateSelected = (event: any) => {
    setEndDate(event.toDate());
    toggleEndDate();
  };

  const toggleStartDate = () => {
    setStartDateOpen((val) => !val);
  };

  const toggleEndDate = () => {
    setEndDateOpen((val) => !val);
  };

  const isMissed = (
    expected_date: string,
    delivered_date: string,
    current_date: string
  ) => {
    return (
      delivered_date !== current_date &&
      dayjs(current_date, 'DD-MM-YYYY') < dayjs(today, 'DD-MM-YYYY') &&
      expected_date !== delivered_date
    );
  };

  const cardClassSelector = (
    dueDays: number,
    expected_date: string,
    delivered_date: string,
    current_date: string
  ) => {
    if (dueDays === 0) {
      return XET_DELIVERY_CARD_STATUSES.same_day;
    } else if (dueDays > 0) {
      if (isMissed(expected_date, delivered_date, current_date)) {
        return XET_DELIVERY_CARD_STATUSES.delayed_expected_yellow;
      } else {
        return XET_DELIVERY_CARD_STATUSES.delayed_actual_red;
      }
    } else {
      if (delivered_date === current_date) {
        return XET_DELIVERY_CARD_STATUSES.early_actual;
      } else {
        return XET_DELIVERY_CARD_STATUSES.early_expected;
      }
    }
  };

  const scheduleStatus = (dueDays: number, delivered_date: string | null) => {
    if (dueDays === 0) {
      return 'DUE';
    }
    if (delivered_date == null) {
      return 'OVERDUE';
    }
    return 'COMPLETED';
  };

  const toggleSelectedDeliveryType = (val: string) => {
    if (selectedDeliveryType !== val) {
      setSelectedDeliveryType(val);
    } else {
      setSelectedDeliveryType(null);
    }
  };

  const getDayLabel = (numberOfDays: number) => {
    return Math.abs(numberOfDays) === 1 ? 'day' : 'days';
  };

  const openDeliveryDetailPage = (id: number) => {
    const url = `${environment.clientUrl}/delivery-detail/${id}`;
    window.open(url, '_blank')?.focus();
  };

  return (
    <>
      <div className="ds-calender-container">
        <div className="ds-calender-content-body align-items-start flex-row">
          <SideNav></SideNav>
          <div className="ds-content-section flex-column gap-24 p-16">
            <div className="ds-header flex-row align-items-center justify-content-space-between">
              <div className="ds-status-tabs-container flex-row align-items-center gap-4">
                <span
                  className={`ds-status-tab xetgo-font-tag bold px-12 py-4 ${
                    selectedDeliveryType === 'Overdue' && 'active-tab'
                  }`}
                  onClick={() => toggleSelectedDeliveryType('Overdue')}
                >
                  Overdue
                </span>
                <span
                  className={`ds-status-tab xetgo-font-tag bold px-12 py-4 ${
                    selectedDeliveryType === 'Due' && 'active-tab'
                  }`}
                  onClick={() => toggleSelectedDeliveryType('Due')}
                >
                  Due
                </span>
                <span
                  className={`ds-status-tab xetgo-font-tag bold px-12 py-4 ${
                    selectedDeliveryType === 'Delivered' && 'active-tab'
                  }`}
                  onClick={() => toggleSelectedDeliveryType('Delivered')}
                >
                  Delivered
                </span>
              </div>

              <div className="ds-filters flex-row align-items-center gap-16">
                <div className="status-toggle flex-row align-items-center p-2">
                  <div
                    className={`xetgo-font-tag bold cursor-pointer px-8 py-4 ${
                      tabSelected === 'VENDOR' ? 'active' : ''
                    }`}
                    onClick={() => setTabSelected('VENDOR')}
                  >
                    Vendor
                  </div>

                  <div
                    className={`xetgo-font-tag bold cursor-pointer px-8 py-4 ${
                      tabSelected === 'CLIENT' ? 'active' : ''
                    }`}
                    onClick={() => setTabSelected('CLIENT')}
                  >
                    Client
                  </div>
                </div>
                <Filter
                  option={{ key: 'Assignees', name: 'Assignees' }}
                  optionList={assignees}
                  multipleSelection={true}
                  onSelection={(val, _option) => {
                    filterByAssignees(val);
                  }}
                  showPlaceholder={true}
                  borderStyle="solid"
                  addNew={false}
                  showId={false}
                  preSelected={selectedAssignees}
                />
                <Filter
                  option={{ key: 'client', name: 'Client Name' }}
                  optionList={clients}
                  multipleSelection={true}
                  onSelection={(val, _option) => {
                    filterByClient(val);
                  }}
                  showPlaceholder={true}
                  borderStyle="solid"
                  addNew={false}
                  showId={false}
                  preSelected={selectedClients}
                />
                <div className="xetgo-font-tag flex-row align-items-center gap-12 date-container px-8 py-4">
                  {formatDate(startDate?.toISOString())}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(startDate)}
                      format={'DD-MM-YYYY'}
                      views={['year', 'month', 'day']}
                      onChange={startDateSelected}
                      open={startDateOpen}
                      className="delivery-date-selector"
                    />
                  </LocalizationProvider>
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240107114755-calendar-range.svg"
                    onClick={toggleStartDate}
                    className="cursor-pointer"
                    height={16}
                    width={16}
                  />
                </div>
                <div className="date-container xetgo-font-tag flex-row align-items-center gap-12 px-8 py-4">
                  {formatDate(endDate?.toISOString())}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(endDate)}
                      format={'DD-MM-YYYY'}
                      views={['year', 'month', 'day']}
                      onChange={endDateSelected}
                      open={endDateOpen}
                      className="delivery-date-selector"
                    />
                  </LocalizationProvider>
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240107114755-calendar-range.svg"
                    onClick={toggleEndDate}
                    className="cursor-pointer"
                    height={16}
                    width={16}
                  />
                </div>
              </div>
            </div>

            {/* WEEKLY CALENDAR VIEW */}

            <div className="ds-weekly-calendar-container">
              <div className="ds-weekly-calendar-view flex-row align-items-center">
                {deliveryScheduleData?.map((deliverySchedule, index) => {
                  return (
                    <div key={index} className="ds-date-column flex-column">
                      <div
                        className={`date-heading flex-column gap-8 ${
                          deliverySchedule.date === today && 'active-date'
                        }`}
                      >
                        <span className="ds-day">
                          {dayjs(deliverySchedule.date, 'DD-MM-YYYY').format(
                            'ddd'
                          )}
                        </span>
                        <div className="flex-row justify-content-space-between align-items-center">
                          <span className="ds-date">
                            {dayjs(deliverySchedule.date, 'DD-MM-YYYY').format(
                              'D'
                            )}
                          </span>
                          <span
                            className="xetgo-font-tag bold"
                            style={{ color: '#ff0000' }}
                          >
                            {
                              deliverySchedule.data.filter(
                                (row) =>
                                  scheduleStatus(
                                    row.due_days,
                                    row.actual_delivery_date
                                  ) === 'OVERDUE'
                              ).length
                            }{' '}
                            Overdue
                          </span>
                          <span
                            className="xetgo-font-tag bold"
                            style={{ color: '#5151ec' }}
                          >
                            {
                              deliverySchedule.data.filter(
                                (row) =>
                                  scheduleStatus(
                                    row.due_days,
                                    row.expected_delivery_date
                                  ) === 'DUE'
                              ).length
                            }{' '}
                            Due
                          </span>
                        </div>
                      </div>
                      <div className="flex-column ds-list p-4 gap-4">
                        {deliverySchedule.data.map(
                          (schedule, scheduleIndex) => {
                            return (
                              <div
                                key={scheduleIndex}
                                className={`delivery-schedule-card cursor-pointer flex-row gap-8 ${
                                  cardClassSelector(
                                    schedule.due_days,
                                    schedule.expected_delivery_date,
                                    schedule.actual_delivery_date || '',
                                    deliverySchedule.date
                                  ).custom_class
                                } `}
                                title={schedule.description}
                                onClick={() =>
                                  openDeliveryDetailPage(schedule.id)
                                }
                              >
                                <div className={`phrase-line`}></div>
                                <div className={`flex-column gap-8  flex-1 `}>
                                  <div className="flex-column gap-4">
                                    <span className="xetgo-font-tag bolder">
                                      {schedule.client_name}
                                    </span>
                                    <span className={`xetgo-font-tag `}>
                                      {schedule.product_name}
                                    </span>
                                  </div>
                                  <div className="flex-column gap-4">
                                    <span className="xetgo-font-tag bolder">
                                      {schedule.city},{' '}
                                      {schedule.delivered_quantity} U
                                    </span>
                                    <span className={`xetgo-font-tag `}>
                                      {schedule.vendor_name}
                                    </span>
                                  </div>
                                  {schedule.due_days !== 0 && (
                                    <p className="delivery-status-box px-6 py-4 xetgo-font-mini bold flex-row align-items-center status-box">
                                      {`${
                                        schedule.actual_delivery_date
                                          ? cardClassSelector(
                                              schedule.due_days,
                                              schedule.expected_delivery_date,
                                              schedule.actual_delivery_date ||
                                                '',
                                              deliverySchedule.date
                                            ).text
                                          : 'Overdue'
                                      } ${Math.abs(
                                        schedule.due_days
                                      )} ${getDayLabel(schedule.due_days)}`}
                                    </p>
                                  )}
                                </div>
                                <span
                                  className="flex-column align-items-center justify-content-space-between"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {schedule.actual_delivery_date ? (
                                    <img
                                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240123141342-check-circle-2.svg"
                                      alt="delivered"
                                      width={16}
                                      height={16}
                                    />
                                  ) : (
                                    <img
                                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240107123640-badge-check.svg"
                                      alt="not-delivered"
                                      className="cursor-pointer"
                                      width={16}
                                      height={16}
                                      onClick={() => markDelivered(schedule.id)}
                                    />
                                  )}
                                  <div className="flex-column gap-4">
                                    <span className="assignee-tooltip-container flex-row align-items-center gap-8">
                                      <Tooltip title={schedule.assignees.name}>
                                        <span className="flex-row align-items-center">
                                          <XetImage
                                            height={16}
                                            width={16}
                                            className="assignee-img"
                                            src={schedule.assignees.avatar}
                                            alt={schedule.assignees.name}
                                          />
                                        </span>
                                      </Tooltip>
                                    </span>
                                    <img
                                      height={16}
                                      width={16}
                                      src={
                                        cardClassSelector(
                                          schedule.due_days,
                                          schedule.expected_delivery_date,
                                          schedule.actual_delivery_date || '',
                                          deliverySchedule.date
                                        ).imageUrl
                                      }
                                      alt="location-icon"
                                    />
                                  </div>
                                </span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default XetDeliveryCalendar;
