import React, { useEffect, useRef, useState } from 'react';
import './PurchaseOrderProducts.scss';
import XetModal from '../../shared/xet-modal/XetModal';
import AddPurchaseProductDialog from '../../AddPurchaseProductDialog/AddPurchaseProductDialog';
import { PurchaseProduct, PurchaseProductDetails } from '../../../models/Task';
import { useDispatch } from 'react-redux';
import {
  deletePurchaseRequestVendor,
  fetchPurchaseRequestComment,
  updatePurchaseOrderProductStart,
} from '../../../redux/oms/omsActions';
import { useSelector } from 'react-redux';
import {
  selectSourceAssignees,
  selectTaskDetail,
  selectVendorMap,
} from '../../../redux/oms/omsSelectors';
import { useParams, useSearchParams } from 'react-router-dom';
import OutsideAlerter from '../../../utils/OutsideClickDetector';
import TaskComment from '../TaskComment/TaskComment';
import { userRoles } from '../../../redux/user/userSelectors';
import AssignVendorDialog from '../../AssignVendorDialog/AssignVendorDialog';
import PurchaseOrderProductCard from '../../PurchaseOrderProductCard/PurchaseOrderProductCard';
import AssigneeSelector from '../AssigneeSelector/AssigneeSelector';
import XetImage from '../../shared/xet-image/XetImage';
import { FilterOptionSchema } from '../../../redux/oms/omsTypes';
import Filter from '../Options/Filter/Filter';

interface PurchaseOrderProductsProps {
  productDetails: PurchaseProductDetails[];
  purchaseRequestId: number;
  poNumber: string;
  salesAssignee: FilterOptionSchema[];
  taskId: number;
}
const BackgroundColors = ['#FADAA4', '#DFE6D2', '#F7F7F7', '#F2BDEF'];

const PurchaseOrderProducts: React.FC<PurchaseOrderProductsProps> = ({
  productDetails,
  purchaseRequestId,
  poNumber,
  salesAssignee,
  taskId,
}) => {
  const dispatch = useDispatch();
  const { category } = useParams();
  const details = useSelector(selectTaskDetail);
  const vendorMap = useSelector(selectVendorMap);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openAssignVendor, setOpenAssignVendor] = useState(false);
  const [openCommentSection, setOpenCommentSection] = useState(-1);
  const quantityRef = useRef<null | HTMLDivElement>(null);
  const [params, setSearchParams] = useSearchParams();
  const [openAssigneeSelection, setOpenAssigneeSelection] = useState(-1);
  const sourceAssignees = useSelector(selectSourceAssignees);

  useEffect(() => {
    const index = parseInt(params.get('comment') || '-1');
    console.log('PARAMS: ', params);
    setOpenCommentSection(index);
    if (index !== -1) {
      dispatch(
        fetchPurchaseRequestComment({
          po_id: purchaseRequestId,
          vendor_id: index,
        })
      );
    }
  }, [params]);

  const updateURLParameter = (param: string, value: any) => {
    setSearchParams((params) => {
      if (value === -1 || value == null) {
        params.delete(param);
      } else {
        params.set(param, value);
      }
      return params;
    });
    console.log('PARAMS UDPATE: ', param, value);
  };

  const handleAddProduct = () => {
    setOpenAddProduct(true);
  };

  const deletePurchaseVendor = (po_id: number, vendor_id: number) => {
    dispatch(deletePurchaseRequestVendor({ po_id, vendor_id }));
  };

  const handleAssignVendor = () => {
    setOpenAssignVendor(true);
  };

  const setVendorAssignee = (data: FilterOptionSchema, vendor_id: number) => {
    dispatch(
      updatePurchaseOrderProductStart({
        assignee_id: data.id,
        id: purchaseRequestId,
        vendor_id,
      })
    );
    setOpenAssigneeSelection(-1);
  };

  return (
    <>
      <div>
        {productDetails.map(
          (vendors: PurchaseProductDetails, vendorIndex: number) => (
            <div
              key={vendorIndex}
              className="purchase-order-card-container p-12"
              style={{
                backgroundColor: BackgroundColors[vendorIndex % 4],
              }}
            >
              <div className="po-upper-product-headings flex-row justify-content-space-between align-items-center">
                <p className="xetgo-font-tag-bolder po-product-name">
                  {vendorMap[vendors.vendor_id]?.name}
                </p>
                <div className="flex-row gap-16 align-items-center">
                  {vendors.vendor_po_number == null && (
                    <span
                      className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                      style={{
                        color: '#5151EC',
                        width: 'fit-content',
                      }}
                      onClick={handleAssignVendor}
                    >
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                        alt="plus"
                        width={16}
                        height={16}
                      />
                      Assign Vendor
                    </span>
                  )}
                  <div className="flex-row gap-4 align-items-center position-relative">
                    <p
                      style={{ color: 'rgb(81, 81, 236)' }}
                      className="title xetgo-font-tag field-key"
                      onClick={() =>
                        setOpenAssigneeSelection((val) =>
                          val === vendors.vendor_id ? -1 : vendors.vendor_id
                        )
                      }
                    >
                      Assignees
                    </p>
                    {openAssigneeSelection === vendors.vendor_id && (
                      <div className="sourcing-assignee-container">
                        <Filter
                          option={{ key: 'sourcing', name: 'Sourcing' }}
                          optionList={sourceAssignees}
                          multipleSelection={false}
                          onSelection={(data: FilterOptionSchema[]) => {
                            setVendorAssignee(data[0], vendors.vendor_id);
                          }}
                          mode="showNames"
                          showPlaceholder={false}
                          borderStyle="solid"
                          preSelected={sourceAssignees.filter(
                            (user) => user.id === vendors.assignee.id
                          )}
                        />
                      </div>
                    )}
                    <div className="flex-row justify-content-flex-end position-relative cursor-pointer">
                      {vendors.assignee?.avatar && (
                        <XetImage
                          className="assignees user-initials-avatar "
                          src={vendors.assignee?.avatar}
                          alt={vendors.assignee?.name}
                          width={22}
                          height={22}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex-row gap-12 align-items-center">
                    <span
                      className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                      style={{
                        color: '#5151EC',
                        width: 'fit-content',
                      }}
                      onClick={handleAddProduct}
                    >
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                        alt="plus"
                        width={16}
                        height={16}
                      />
                      Add product
                    </span>
                    {category === 'sales-order' && (
                      <div
                        className="vendor-delete-container flex-row align-items-center justify-content-center"
                        onClick={() => {
                          deletePurchaseVendor(
                            vendors.purchase_request_id,
                            vendors.vendor_id
                          );
                        }}
                      >
                        <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114095750-trash.svg"
                          alt="delete"
                          width={18}
                          height={18}
                          className="cursor-pointer"
                        />
                      </div>
                    )}
                  </div>

                  {vendors.vendor_po_number != null && (
                    <div className="po-number py-4 px-12">
                      <p className="xetgo-font-button-bolder">
                        {vendors.vendor_po_number}
                      </p>
                    </div>
                  )}
                  <div
                    className="product-comment-container-open flex-row align-items-center justify-content-center"
                    onClick={() => {
                      updateURLParameter('comment', vendors.vendor_id);
                    }}
                  >
                    <img
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117070437-chatbubbles.svg"
                      alt="chat-icon"
                      width={18}
                      height={18}
                    />
                  </div>
                </div>
              </div>
              <div className="purchase-order-all-products-container flex-column gap-12">
                {vendors.products.map(
                  (product: PurchaseProduct, productIndex: number) => (
                    <PurchaseOrderProductCard
                      key={productIndex}
                      product={product}
                      productIndex={productIndex}
                      vendorId={vendors.vendor_id}
                      vendorIndex={vendorIndex}
                    />
                  )
                )}
              </div>
            </div>
          )
        )}
        {productDetails.length === 0 && (
          <div className="m-16 p-12 flex-row no-product-container">
            <span
              className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
              style={{
                color: '#5151EC',
                width: 'fit-content',
              }}
              onClick={handleAddProduct}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                alt="plus"
                width={16}
                height={16}
              />
              Add Product
            </span>
          </div>
        )}
      </div>
      {productDetails && (
        <XetModal
          backdropClose={false}
          open={openAddProduct}
          handleClose={() => setOpenAddProduct(false)}
        >
          <AddPurchaseProductDialog
            handleClose={() => {
              setOpenAddProduct(false);
              location.reload();
            }}
            purchaseRequestId={purchaseRequestId}
          />
        </XetModal>
      )}
      {productDetails && (
        <XetModal
          backdropClose={false}
          open={openAssignVendor}
          handleClose={() => setOpenAssignVendor(false)}
        >
          <AssignVendorDialog
            handleClose={() => {
              setOpenAssignVendor(false);
              location.reload();
            }}
            purchaseRequestId={purchaseRequestId}
          />
        </XetModal>
      )}

      {openCommentSection !== -1 && (
        <div className="comment-floating-section">
          <OutsideAlerter
            action={() => {
              updateURLParameter('comment', -1);
            }}
          >
            <TaskComment
              purchase_request_id={purchaseRequestId}
              vendor_id={openCommentSection}
              handleClose={() => {
                updateURLParameter('comment', -1);
              }}
            />
          </OutsideAlerter>
        </div>
      )}
    </>
  );
};

export default PurchaseOrderProducts;
